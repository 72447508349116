<template>
  <div>
    <app-collapse hover>
      <app-collapse-item title="Filters">
        <recruiter-filter
          :status-filter.sync="statusFilter"
          :status-options="hireRequestStatus"
          :assign-filter.sync="assignFilter"
          :assign-options="assignOptions"
          :created-by-filter.sync="createdByFilter"
          :created-by-filter-options="createdByFilterOptions"
          :shift-type-filter.sync="shiftTypeFilter"
          :shift-type-filter-options="shiftTypeFilterOptions"
        />
      </app-collapse-item>
    </app-collapse>
    <recruiter-add
      :interview-id.sync="nhrId"
      :user-data.sync="dataLocal"
      :is-add-new-interview-sidebar-active.sync="isAddNewInterviewSidebarActive"
      :recruiters-options.sync="recruitersOptions"
      @refetch-data="refetchData"
    />
    <candidate-add
      :interview-id.sync="nhrId"
      :user-data.sync="dataLocal"
      :is-add-new-candidate-sidebar-active.sync="isAddNewCandidateSidebarActive"
      :candidates-options.sync="candidatesOptions"
      @refetch-data="refetchData"
    />
    <close-n-h-r
      :interview-id.sync="nhrId"
      :user-data.sync="dataLocal"
      :is-add-new-close-sidebar-active.sync="isAddNewCloseSidebarActive"
      :employee-options.sync="employeeOptions"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              class="mr-2"
              v-model="allSelected"
              v-if="nhrMetaData.length && $can('hire_request_delete')"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              class="d-flex align-items-center justify-content-end"
              v-if="selectedItems.length == 0"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Role/Team"
                />
              </b-input-group>

              <!--<b-button
                variant="primary"
                :to="{name: 'add-nhr'}"
              >
                <span class="text-nowrap">Raise NHR</span>
              </b-button>-->
              <b-button
                v-if="$can('hire_request_create')"
                variant="primary"
                :to="{ name: 'add-nhr' }"
              >
                <span class="text-nowrap">Raise New Hire Request</span>
              </b-button>
            </div>
            <b-button
              v-else-if="$can('hire_request_delete')"
              class="float-right"
              variant="danger"
              :disabled="selectedItems.length == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ selectedItems.length }} Hire Request</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedItems"
        name="selectedItems"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchNHRList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>

          <!-- Column: User -->
          <template #cell(role)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('hire_request_show')"
                :to="{ name: 'all-view-nhr', params: { id: data.item.hashid } }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.role"
              >
                {{ data.item.role | str_limit(15) }}
              </b-link>
              <span v-else v-b-tooltip.hover.v-default :title="data.item.role">
                {{ data.item.role | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <template #cell(nhr_date)="data">
            <div class="text-nowrap">
              {{ data.item.nhr_date | formatDate }}
            </div>
          </template>

          <!-- Column: User -->
          <!-- <template #cell(status_label)="data">
          <b-media vertical-align="center">
            <v-select
              v-model="data.item.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="nhrStatusOption"
              :reduce="(label) => label.code"
              label="label"
              :clearable="false"
              @input="changeStatus(data.item, data.item.status)"
              :searchable="false"
            />
          </b-media>
        </template> -->

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="viewRecord(data)"
                v-if="$can('hire_request_show')"
              >
                <feather-icon icon="EyeIcon" size="13" class="mr-50" />
                <span>View</span>
              </b-dropdown-item>
              <b-dropdown-item
                class="btn-sm p-0"
                @click="editRecord(data)"
                v-if="$can('hire_request_edit')"
              >
                <feather-icon icon="EditIcon" size="13" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                class="btn-sm p-0"
                @click="recruiter(data)"
                v-if="
                  data.item.status !== 3 &&
                  ($can('Zircly Admin') || $can('Recruiter'))
                "
              >
                <feather-icon icon="PenToolIcon" size="13" class="mr-50" />
                <span>Recruiters</span>
              </b-dropdown-item>
              <b-dropdown-item
                class="btn-sm p-0"
                @click="candidates(data)"
                v-if="
                  data.item.status !== 3 &&
                  ($can('Zircly Admin') || $can('Recruiter')) &&
                  data.item.assigned_to_me
                "
              >
                <feather-icon icon="UserIcon" size="13" class="mr-50" />
                <span>Candidates</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                v-b-modal.modal-login
                @click="closeNhr(data)"
                v-if="
                  data.item.status !== 3 &&
                  ($can('Zircly Admin') || $can('Recruiter'))
                "
              >
                <feather-icon icon="XCircleIcon" size="13" class="mr-50" />
                <span>Add Employee</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="$can('hire_request_delete')"
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-if="selectedItems.length == 0"
              v-model="currentPage"
              :total-rows="totalNHRList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('hire_request_delete')"
              class="float-right"
              variant="danger"
              :disabled="selectedItems.length == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ selectedItems.length }} Hire Requests</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BButton,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import nhrStoreModule from "../../nhrStoreModule";
import useNHRList from "./useNHRList";
import RecruiterAdd from "./RecruiterAdd.vue";
import CandidateAdd from "./CandidateAdd.vue";
import CloseNHR from "./CloseNHR.vue";
import RecruiterFilter from "./RecruiterFilter.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BLink,
    BButton,
    BPagination,
    BFormCheckbox,
    BFormCheckboxGroup,

    vSelect,
    RecruiterAdd,
    CandidateAdd,
    RecruiterFilter,
    CloseNHR,

    AppCollapse,
    AppCollapseItem,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const NHR_APP_STORE_MODULE_NAME = "app-nhr";

    var isDeleteMultipleChecked = ref(false);
    var selectedItems = ref([]);
    var itemDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(NHR_APP_STORE_MODULE_NAME)) {
      store.registerModule(NHR_APP_STORE_MODULE_NAME, nhrStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(NHR_APP_STORE_MODULE_NAME))
        store.unregisterModule(NHR_APP_STORE_MODULE_NAME);
    });

    const assignOptions = [{ label: "Assigned To Me", code: 0 }];

    const isAddNewInterviewSidebarActive = ref(false);
    const isAddNewCandidateSidebarActive = ref(false);
    const isAddNewCloseSidebarActive = ref(false);

    const {
      fetchNHRList,
      tableColumns,
      perPage,
      currentPage,
      totalNHRList,
      dataMeta,
      nhrMetaData,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      assignFilter,
      createdByFilter,
      shiftTypeFilter,
      hireRequestStatus,
      statusFilter,
    } = useNHRList();

    // watch(isDeleteMultipleChecked, (val) => {
    //   selectedItems.value = [];
    //   if (val) {
    //     nhrMetaData.value.forEach((item) => {
    //       selectedItems.value.push(item.hashid);
    //     });
    //   }
    // });

    // watch(selectedItems, (val) => {
    //   // console.log(selectedItems.value);
    //   // console.log(itemDeleteCount.value);
    //   itemDeleteCount.value = val.length;
    // });

    return {
      // Sidebar
      fetchNHRList,
      tableColumns,
      nhrMetaData,
      perPage,
      currentPage,
      totalNHRList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      assignOptions,
      assignFilter,
      createdByFilter,
      shiftTypeFilter,
      isDeleteMultipleChecked,
      itemDeleteCount,
      selectedItems,
      isAddNewInterviewSidebarActive,
      isAddNewCandidateSidebarActive,
      isAddNewCloseSidebarActive,
      hireRequestStatus,
      statusFilter,
    };
  },
  data() {
    return {
      newUserData: this.$cookies.get("userData"),
      nhrId: "",
      createdByFilterOptions: [],
      dataLocal: {
        recruiters: "",
        candidates: [],
        employees: [],
        status: false,
      },
      hire_request_status: "",
      nhrStatusOption: [
        { label: "Approval Pending", code: 0 },
        { label: "Request Approved", code: 1 },
        { label: "In Progress", code: 2 },
        { label: "Request Completed", code: 3 },
      ],
      shiftTypeFilterOptions: ["US/UK", "INDIA"],
      recruitersOptions: [],
      candidatesOptions: [],
      employeeOptions: [],
      // selectedItems: [],
      is_loading: false,
      allSelected: false,
    };
  },
  created() {
    if (!this.$can("hire_request_delete")) {
      this.tableColumns = this.tableColumns.filter(
        (item) => item.key != "select"
      );
    }
    this.$http
      .get("/list/bba")
      .then((res) => {
        this.recruitersOptions = res.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    this.$http
      .get("/list/candidates")
      .then((res) => {
        this.candidatesOptions = res.data.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .get("/list/employees")
      .then((res) => {
        this.createdByFilterOptions = res.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    changeStatus(nhrData, status) {
      this.$store
        .dispatch("app-nhr/updateNHRStatus", {
          nhrData,
        })
        .then((res) => {
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Hire Request Updated",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Hire Request Updating Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Candidate Updating Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    viewRecord(data) {
      this.$router.push(`/all/view/nhr/${data.item.hashid}`).catch(() => {});
    },
    openWindow(link) {
      window.open(link);
    },
    recruiter(data) {
      this.nhrId = data.item.hashid;
      const self = this;
      this.$store
        .dispatch("app-nhr/fetchNHR", this.nhrId)
        .then((res) => {
          self.dataLocal.recruiters = [];
          if (res.data.data.recruiters.length > 0) {
            self.dataLocal.recruiters = res.data.data.recruiters[0].hashid;
          }
          self.isAddNewInterviewSidebarActive = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    candidates(data) {
      this.nhrId = data.item.hashid;
      const self = this;
      this.$store
        .dispatch("app-nhr/fetchNHR", this.nhrId)
        .then((res) => {
          self.dataLocal.candidates = [];
          res.data.data.candidates.forEach((item) => {
            self.dataLocal.candidates.push(item.hashid);
          });
          self.isAddNewCandidateSidebarActive = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    closeNhr(data) {
      this.nhrId = data.item.hashid;
      this.$http
        .get(`/list/employee-available-for-nhr?nhr_id=${this.nhrId}`)
        .then((res) => {
          this.employeeOptions = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      const self = this;
      this.$store
        .dispatch("app-nhr/fetchNHR", this.nhrId)
        .then((res) => {
          if (res.data.data.status === 3) {
            self.dataLocal.status = true;
          }
          self.dataLocal.employees = [];
          res.data.data.employees.forEach((item) => {
            self.dataLocal.employees.push(item.hashid);
          });
          self.isAddNewCloseSidebarActive = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    editRecord(data) {
      this.$router.push(`/all/edit/nhr/${data.item.hashid}`).catch(() => {});
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.role}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedItems.length} Hire Request, This cannot be undone`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-nhr/removeMultipleNHR", this.selectedItems)
            .then((res) => {
              this.isDeleteMultipleChecked = false;
              this.selectedItems = [];
              if (res.data.success) {
                this.refetchData();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted Successfully",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Hire Request Deletion failed",
                    icon: "BellIcon",
                    variant: "danger",
                    text: res.data.message,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-nhr/removeNHR", id)
        .then((res) => {
          if (res.data.success) {
            self.refetchData();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "New Hire Request Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "New Hire Request deleted successfully",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Hire Request Deletion failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  watch: {
    allSelected(val) {
      this.selectedItems = [];
      if (val) {
        this.nhrMetaData.forEach((item) => {
          this.selectedItems.push(item.hashid);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
