import { render, staticRenderFns } from "./NHRList.vue?vue&type=template&id=0b31b1dc&scoped=true&"
import script from "./NHRList.vue?vue&type=script&lang=js&"
export * from "./NHRList.vue?vue&type=script&lang=js&"
import style0 from "./NHRList.vue?vue&type=style&index=0&id=0b31b1dc&lang=scss&scoped=true&"
import style1 from "./NHRList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b31b1dc",
  null
  
)

export default component.exports