import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function NHRList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "select", sortable: false },
    { key: 'role', sortable: true },
    { key: 'team', sortable: true },
    { key: 'no_of_positions', sortable: true, label: 'Positions' },
    { key: 'nhr_date' , sortable: true},
    { key: 'status_label',label: 'Status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalNHRList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const assignFilter = ref(null)
  const createdByFilter = ref(null)
  const shiftTypeFilter = ref(null)
  const nhrMetaData = ref([]);
  const hireRequestStatus = ref([])
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalNHRList.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, assignFilter,createdByFilter,shiftTypeFilter,statusFilter], () => {
    refetchData()
  })

  const fetchNHRList = (ctx, callback) => {
    store
      .dispatch('app-nhr/fetchNHRs', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        assign: assignFilter.value,
        createdByFilter: createdByFilter.value,
        shiftTypeFilter: shiftTypeFilter.value,
        statusFilter: statusFilter.value,
      })
      .then(response => {
        const nhr = response.data.data
        const { total } = response.data.meta
        hireRequestStatus.value = response.data.defaultHireRequestStatus

        callback(nhr)
        totalNHRList.value = total
        nhrMetaData.value = nhr;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching NHR's",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchNHRList,
    tableColumns,
    perPage,
    currentPage,
    totalNHRList,
    nhrMetaData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
    assignFilter,
    createdByFilter,
    shiftTypeFilter,
    hireRequestStatus,
    statusFilter,
  }
}
